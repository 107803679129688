<template>
  <v-app>
    <!-- <home-system-bar v-if="menubar"/> -->

    <home-app-bar v-if="menubar"/>

    <home-back-bar v-if="menubar === false"/>

    <home-view />

    <home-footer />

    <!-- <home-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeBackBar: () => import('@/layouts/home/BackBar'),
      HomeFooter: () => import('@/layouts/home/Footer'),
      // HomeSettings: () => import('@/layouts/home/Settings'),
      // HomeSystemBar: () => import('@/layouts/home/SystemBar'),
      HomeView: () => import('@/layouts/home/View')
    },
    computed: {
      menubar () {
        return this.$route.meta.menubar
      },
    }
  }
</script>
